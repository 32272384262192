<template>
  <div>
    <div class="titleBox" >
      <div>
        <img src="../../assets/meetList.png" />
        <span>{{ typeInfo.memberName }}缴费</span>
      </div>
      <div style="margin-left: 55px; font-size: 16px;">{{ typeInfo.memberName }}</div>

    </div>
    <div class="infoBox">
      <div class="padding-12">
        <Form
          @submit="onSubmit"
          input-align="right"
          :show-error-message="false"
        >
          <Field
            v-if="!['个人常务理事','个人理事'].includes(typeInfo.memberName)"
            readonly
            clickable
            name="picker"
            :value="form.orgs.deptName"
            label="分支机构"
            placeholder="请选择分支机构"
            right-icon="arrow"
            @click="handleShowPicker('org')"
            :rules="[{ required: true, message: '请选择分支机构' }]"
          />
          <Field
            v-model.trim="form.mobile"
            name="联系人手机号"
            label="联系人手机号"
            placeholder="请输入联系人手机号"
            disabled
            readonly
            onfocus="this.blur()"
            readonly="readonly"
            :rules="[{ required: true, message: '请输入联系人手机号' }]"
          />
          <Field
            v-model.trim="form.company"
            name="单位名称"
            label="单位名称"
            placeholder="请输入单位名称"
            :maxlength="25"
            :rules="[{ required: true, message: '请输入单位名称' }]"
          />
          <Field
            v-model.trim="form.name"
            name="联系人姓名"
            label="联系人姓名"
            placeholder="请输入联系人姓名"
            :maxlength="10"
            :rules="[{ required: true, message: '请输入联系人姓名' }]"
          />
          <Field
            readonly
            clickable
            clearable
            name="picker"
            :value="form.season"
            label="缴费年度"
            placeholder="请选择缴费年度"
            right-icon="arrow"
            @click="handleShowPicker('season')"
            :rules="[{ required: false, message: '请选择缴费年度' }]"
          />
<!--          -->
          <Cell title="年费金额" :value="'￥' + typeInfo.memberAmount" />

          <Cell title="凭证附件" v-if="typeInfo.payType=='remit'">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
              <Icon name="arrow" size='16' color="#969799"/>
              <input
                type="file"
                multiple="multiple"
                id="takeImage"
                name="take-picture"
                @change="uploadFile('takeImage')"
                accept="image/*"
                class="fileBox"

              />
            </template>
          </Cell>
          <div class="imgBox">
            <div v-for='item in imgList' :key='item'>
              <img  :src="item" alt=""  width='80px' height="80px"/>
              <span class="deleteImg" @click="deleteImg(item)"><Icon name="cross" /></span>
            </div>
          </div>
          <div style="margin-top:0.4rem">
            <Button
              block
              type="info"
              native-type="submit"
              :loading="loading"
              color="#2b72ff"
              loading-text="加载中"
              > {{text}}</Button
            >
          </div>
        </Form>
      </div>
    </div>
    <Popup v-model="showPicker" position="bottom">
      <Picker
        show-toolbar
        :columns="pickerList"
        @confirm="onConfirmPicker"
        @cancel="showPicker = false"
        value-key="deptName"
      />
    </Popup>
  </div>
</template>

<script>
import { Form, Field, Cell, Button, Popup, Picker, Notify,Icon,Toast} from "vant";
import { getOrgList, payMember } from "@/api/member.js";
import { pay } from "@/api/pay.js";
import config from '@/utils/config.js'
import $ from "jquery";
import {getTypeList} from "@/api/member.js"

var u = navigator.userAgent;
var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

export default {
  name: "memberPayFee",
  components: {
    Icon,
    Toast,
    Form,
    Field,
    Cell,
    Button,
    Popup,
    Picker,
    [Notify.Component.name]: Notify.Component,
  },
  data() {
    return {
      money: "",
      text:'立即缴费',
      form: {
        orgs: "",
        mobile: "",
        company: "",
        name: "",
        season: "",
      },
      showPicker: false,
      pickerList: [],
      pickerType: "",
      //分支机构列表的第一项必须是无，重要
      orgList: [],
      yearList: [],
      typeInfo: {},
      orderId: "",
      loading: false,
      imgList:[],
      imgListH5:[],
    };
  },
  created() {
    let year = new Date().getFullYear();
    let year02 = `${year - 4}年`;
    let year01 = `${year - 3}年`;
    let year0 = `${year - 2}年`;
    let year1 = `${year - 1}年`,
      year2 = `${year}年`,
      year3 = `${year * 1 + 1}年`;

    this.yearList = [ year2, year1,year0,year01,year02];
    // this.form.season = year3;

    this.typeInfo = JSON.parse(this.$route.query.infos);
    if(!this.typeInfo.id){
      this.$router.go(-1)
    }
    this.form.mobile = localStorage.getItem("mobile");
    if(this.typeInfo.form){
      this.form={...this.typeInfo.form}
    }
    this.getOrgList();
    console.log(this.typeInfo.payType )
    if(this.typeInfo.payType=='pay'){
      this.text='立即缴费'
    }else {
      this.text='提交审核'
    }

  },
  methods: {
    changeYear(){
      console.log(this.typeInfo)
      // if(['分支机构单位会员'].includes(this.typeInfo.memberName)){
        getTypeList({
          memberDept:this.form.season.replace('年',''),
          memberName:this.typeInfo.memberName,
          // id:this.typeInfo.id
        }).then(res=>{
          if(res.status=='S'){
            if(res.data[0].memberAmount){
              this.typeInfo.memberAmount= res.data[0]&&res.data[0].memberAmount||''
            }
          }
        })
      // }
    },
    blur(){

    },
     uploadFile(id){
    let self = this;
     if (self.imgList.length >= 5) {
       Toast.info('上传文件数量不得大于5个！', 2);
       return false;
     }
    var input = document.getElementById(id);
    let arr = [];

    var len = input.files.length; // 对于input上传的图片，使用dom.files可以获取图片信息
    Toast.loading({
        message: '上传中...',
        forbidClick: true,
      });
    for (let i = 0; i < len; i++) {
      let fileImg = new FileReader();


      fileImg.readAsDataURL(input.files[i]); // readAsDataURL方法将图片转为base64格式存储于result中
      fileImg.onload = function() {
        // 使用FileReader的result属性获取图片base64信息
        let formData = new FormData();
        formData.append('file', input.files[i]);
        $.ajax({
          url: config.baseUrl.dev + '/meetInfo/uploadPdfArray',
          type: 'POST',
          contentType: false,
          processData: false,
          data: formData,
          async: false,
          mineType: 'multipart/form-data',
          dataType: 'json',
          xhrFields: {
            // withCredentials: true,
          },
          success: response => {
            if(i==len-1){
              Toast.clear();
            }
            if (self.imgList.length >= 5) {
              Toast.info('上传文件数量不得大于5个！', 2);
              return false;
            }
            if (response.status == 'S' && response.data.filePathH5) {
              let list = [...self.imgList];
              let listH5=[...self.imgListH5];

              list.push(response.data.filePathPC);
              listH5.push(response.data.filePathH5)
              self.imgList=[...list]
              self.imgListH5=[...listH5]

            }
          },
          error: function(e) {
            console.log(e);
            Toast.clear();
          },
        });
      };
      fileImg.onerror = function(e) {
        console.log('error' + e);
      };
    }
  },
  deleteImg(url){
    let list=[...this.imgList]
    let listH5=[...this.imgListH5]
    let idx=list.findIndex(item=>url==item);
    list.splice(idx, 1);
    listH5.splice(idx,1)
    this.imgList=[...list]
    this.imgListH5=[...listH5]
  },
    getOrgList() {
      getOrgList().then((res) => {
        if (res.status == "S") {
          if(this.typeInfo.memberName=='分支机构单位会员'||this.typeInfo.memberName=='分支机构个人会员'){
            this.orgList = [...res.data];
          }else{
            this.orgList = [{ id: "", deptName: "无" }, ...res.data];
          }

          if(this.typeInfo.memberName=='常务理事单位'||this.typeInfo.memberName=='理事单位'){
            this.form.orgs=this.orgList[0]
          }
        }
      });
    },
    handleShowPicker(type) {
      if (type == "org") {
        if(this.typeInfo.memberName=='常务理事单位'||this.typeInfo.memberName=='理事单位'){
         return
        }
        this.pickerList = [...this.orgList];

      } else {
        this.pickerList = [...this.yearList];
      }
      this.showPicker = true;
      this.pickerType = type;
    },
    async onSubmit() {
      let pattern = /^(13|14|15|17|18|19|16)[0-9]{9}$/;
      if (!pattern.test(this.form.mobile)) {
        Notify({ type: "warning", message: "请输入正确格式的手机号码" });
        return false;
      }
      if(this.typeInfo.payType=='remit'&&this.imgList<1){
        Notify({ type: "warning", message: "请上传凭证附件！" });
        return false;
      }
      let season=this.form.season.split("年")[0];
      //this.$router.push('/payFailed');
      let params = {
        branchDept: this.form.orgs&&this.form.orgs.id||'',
        company: this.form.company,
        id: this.typeInfo&&this.typeInfo.memberId||'',
        memberType: this.typeInfo.id,
        mobile: this.form.mobile,
        name: this.form.name,
        paymentAmount: this.typeInfo.memberAmount,
        season,
        fileUrl:this.imgList.length>0?this.imgList.join(";"):'',
        fileUrlH5:this.imgListH5.length>0?this.imgListH5.join(';'):'',
        paymentMethod:this.typeInfo.payType=='remit'?'2':'0',
        clientId:localStorage.getItem('clientId')||''
      };
      this.loading = true;
      let res = await payMember(params);
      if (res.status == "S") {
        this.orderId = res.data;
        if(this.typeInfo.payType=='remit'){//线上汇款
          Toast.success("提交成功");
          this.loading=false;
          this.$router.push("/memberRecord?orderId="+res.data);
        }
        else{//直接付款
          let payRes = await pay({
            merOrderId: res.data,
            totalAmount: this.typeInfo.memberAmount,
            orderType:'1'
          });

          if (payRes.status == "S") {
            let url = payRes.data;
            if (isAndroid) {
              //android终端
              window.open(url);
            } else if (isiOS) {
              //ios终端
              window.location.href = url;
            }
          } else {
            this.loading = false;
          }
        }
      } else {
        this.loading = false;
      }
    },
    onConfirmPicker(val) {
      console.log(val)

      if (this.pickerType == "org") {
        this.form.orgs = val;
      } else {
        this.form.season = val;
      }
      this.showPicker = false;
      this.changeYear()
    },
  },
  beforeDestroy(){
     this.loading=false
  }
};
</script>

<style lang="less" scoped>
::v-deep.van-button {
  border-radius: 5px;
  height: 0.35 * 1.25rem;
}
::v-deep.van-cell {
  padding: 0.2rem 0.18rem;
  .van-field__label {
    color: #333;
  }
  .van-field__control {
    color: #999;
  }
  .van-cell__value {
    color: #f62f2f;
  }
}
.titleBox {
  background: url("../../assets/detailBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 1.4rem;
  padding: 0 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  padding-top: 0.46rem;
  box-sizing: border-box;
  img {
    margin-right: 0.22rem;
    width: 0.28rem;
  }
}
.infoBox {
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  margin-top: -0.2rem;
  .padding-12 {
    padding: 0.12rem;
  }
}
.imgBox{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  div{
    position: relative;
    width: 80px;
    height: 80px;
     margin-right: 10px;
    margin-top: 5px;
  }

  .deleteImg{
    position: absolute;
    z-index: 10;
    top:0px;
    right:0px;
    display: block;
    width: 14px;
    height: 14px;
    background: rgba(0,0,0,0.3);
    color: #fff;
    font-size: 12px;
    border-radius: 7px;
    text-align: center;
    line-height: 14px;

  }

}
.fileBox{
width:100%; height:100%;position:absolute;top:0;right:0;z-index:10;background:yellow;opacity:0
}
</style>
